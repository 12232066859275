*:focus-visible {
    outline: 3px dashed white !important;
}
@media only screen and (max-width: 450px) {
    button {
        font-size:20px !important;
    }
}
@media only screen and (min-width: 768px) {
    .requiresArrow {
        position: relative;
    }

    .requiresArrow:before {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        border: 3px solid white;
        border-bottom: 0;
        border-left: 0;
        transform: rotate(135deg);
        left: 50%;
        top: 80px;
        margin-left: -15px;
        animation: mrtdBounce 1s infinite;
    }
}
#mrtd_btn_submitted {
    color:#bdbdbd;
}
.mrtd_tick {
    position:relative;
}
.mrtd_tick:after {
    position:absolute;
    content:'';
    width:20px;
    height:10px;
    border: 2px solid green;
    border-bottom:0;
    border-left:0;
    transform:rotate(130deg);
    right:20px;
    top:20px;
}
