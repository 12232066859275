@keyframes mrtd_pulse {
0% {
        box-shadow: 0 3px 0 0 rgba(255, 255, 255, 0.7);
    }
85% {
         box-shadow: 0 3px 0 30px rgba(255, 255, 255, 0);
     }
100% {
          box-shadow: 0 3px 0 0 rgba(255, 255, 255, 0);
      }
}

@keyframes mrtdBounce {
    50% {
        transform: translateY(-15px) rotate(135deg);
    }
}
