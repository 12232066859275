#dot-pattern-2-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    height: var(--vh100);
    width: 100%;
    pointer-events: none;
    overflow: hidden;
}
#dot-pattern-2 {
    background-color: transparent;
    opacity: .7;
}
