html {
    height: 100%;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    overflowY: auto;
}

:root {
    --vh : 1vh;
    --vh100 : 100vh;
}
